import axios from "axios"
export default {
  data() {
    return {
      weburl: "https://www.rayscloud.cn/webapi",
      siteId: localStorage.getItem("siteId"),
      menuId: localStorage.getItem("menuId"),
      navList: [],
      contlist: [],
      childId: 0,
    };
  },
  methods: {
    getchild() {
      axios
        .get(`${this.weburl}/api/Site/Menu/list/${this.siteId}/${this.menuId}`)
        .then((res) => {
          if (res.status == 200) {
            this.navList = res.data.data;
            if (this.navList.length > 0) {
              this.childId = this.navList[0].serId;
              this.getContent();
            }
          }
        })
    },
    setmenuId(name) {
      this.childId = name;
      localStorage.setItem("childId", this.childId);
      this.getContent();
    },
    getContent() {
      let searchstr = {
        page: 1,
        pageSize: 999,
        siteId: this.siteId,
        menu: this.menuId,
        child: this.childId,
        // childs: this.childIds,
        form: "font",
      };
      axios
        .post(`${this.weburl}/api/Cont/list`, searchstr)
        .then((res) => {
          if (res.status == 200) {
            this.contlist = res.data.data.data;
          }
        })
    },
    getchild2() {
      axios
        .get(`${this.weburl}/api/Site/Menu/${this.siteId}/${this.childId}`)
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem("childId", this.childId);
            this.contlist = res.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goInfo(item) {
      localStorage.setItem("secondMenu", JSON.stringify(item));
      this.$router.push(item.link);
    },
  },
  created() {
    this.getchild();
  },
};